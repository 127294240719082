<!-- Flip Pixel main. Also contains the pixel menu that shows on the left -->
<template>
  <div class="flippixel-container">
    <div class="ss-left-nav">
      <div class="ss-left-nav-content">
        <div class="ss-nav-title">Pixel</div>
        <div class="ss-lnav-links" @click="setRightPanel('code')">
          <font-awesome-icon :icon="['far', 'code']" class="inline-fa" />
          <div class="ss-lnav-txt">Pixel Code</div>
        </div>
        <div class="ss-lnav-links" @click="setRightPanel('verify')">
          <font-awesome-icon :icon="['far', 'check']" class="inline-fa" />
          <div class="ss-lnav-txt">Pixel Verification</div>
        </div>
        <div class="ss-lnav-links" @click="openWindow()">
          <font-awesome-icon :icon="['far', 'book-open']" class="inline-fa" />
          <div class="ss-lnav-txt">Pixel Installation Guide</div>
        </div>
        <!-- <div class="ss-lnav-links">
          <font-awesome-icon :icon="['far', 'info-square']" class="inline-fa" />
          <div class="ss-lnav-txt">FAQ</div>
        </div> -->
      </div>
    </div>
    <div v-if="rightPanel === 'code'">
      <flip-pixel-code
        :pixel-id="universalPixelId"
        :advertiser-data="advertiser"
        :is-current-user-external="isCurrentUserExt"
      />
    </div>
    <div v-if="rightPanel === 'verify'">
      <flip-pixel-verification :pixel-id="universalPixelId" />
    </div>
  </div>
  <!-- link wrap end -->
</template>

<script>
import { get, sync } from 'vuex-pathify';
import { PIXEL_INSTALLATION_GUIDE_URL } from '~/constant';

export default {
  name: 'FlipPixel',
  components: {
    FlipPixelCode: () =>
      import(/* webpackChunkName: "flip-pixel-code" */ '~/pages/FlipPixelCode.vue'),
    FlipPixelVerification: () =>
      import(/* webpackChunkName: "flip-pixel-verification" */ '~/pages/FlipPixelVerification.vue'),
  },
  data() {
    return {
      rightPanel: 'code',
      isCurrentUserExt: true,
    };
  },
  computed: {
    currentUser: get('common/currentUser'),
    advertiser: get('common/advertiser'),
    account: sync('common/account'),
    universalPixelId: get('common/universalPixelId'),
  },
  async mounted() {
    if (!this.advertiser) {
      // this.$router.push({ name: 'Home' });
    }
    if (this.currentUser) {
      // accountInfoAvailable, paymentInfoAvailable are determined by the server.
      // Other flags are set by external users, NOT internal.
      const role = this.currentUser?.role?.name;
      this.isCurrentUserExt = role
        ? !['INTERNAL_ADMIN', 'INTERNAL_SUB_ADMIN', 'INTERNAL_USER'].includes(role)
        : false;
    }
  },
  methods: {
    async setRightPanel(selection) {
      this.rightPanel = selection;
    },
    openWindow() {
      window.open(PIXEL_INSTALLATION_GUIDE_URL, '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;700;800';
@import 'https://fonts.googleapis.com/css2?family=Vollkorn:wght@400;500;600;700;800;900&display=swap';

body {
  padding: 0;
  margin: 0;
}
// .ss-main-wrap .filled label, .mvc-form-wrap3 .focused label, .focused label
.ss-left-nav {
  position: fixed;
  top: 47px;
  left: 58px;
  z-index: 2;
  width: 350px;
  height: 98vh;
  vertical-align: baseline;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.inline-fa {
  box-sizing: border-box;
  display: inline-block;
  width: 1.125em;
  height: 1em;
  overflow: visible;
  font-size: 16px;
  color: var(--primarycolor);
  vertical-align: -0.125em;
}
.ss-lnav-txt {
  display: inline-block;
  margin: 0px 8px;
  font-size: 15px;
  color: #1e2124;
  cursor: pointer;
  transition: color 0.2s ease;
  &:hover {
    color: var(--primarycolor);
  }
}
.flippixel-container {
  background-color: white;
  background-size: cover;
  font-size: 16px;
  color: #48525c;
  line-height: 1.7;
  font-weight: 500;
  font-family: 'Manrope', sans-serif;
  max-height: calc(100vh);
  overflow-y: scroll;
  margin-left: 58px;
}
.ss-lnav-links {
  padding-left: 12px;
  margin-top: 20px;
}
.ss-left-nav-content {
  max-height: calc(100vh - 100px);
  padding: 48px 45px;
  overflow-y: scroll;
}
.ss-nav-title {
  display: inline-block;
  font-size: 14px;
  color: #1e2124;
  text-transform: uppercase;
  letter-spacing: 0.03em;
  font-weight: 700;
}
</style>
